import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import MessengerCustomerChat from 'react-messenger-customer-chat';

import RouterRoute from "./router/router";


ReactDOM.render(
  <React.StrictMode>
      <MessengerCustomerChat pageId="103555657749375" appId="711734009653619" />
    <Router>
      <ScrollToTop>
        <RouterRoute />
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
