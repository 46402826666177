import React from 'react';

// styles
import styles from "./responsiveBox.module.scss";

const ResponsiveBox = (props) => {
    return (  
        <div className={` ${styles.box} ${props.className}`}>
            {props.children}
        </div>
    );
}
 
export default ResponsiveBox;