// React
import React from "react";

// Local Imports
import { Header, Footer } from "../../layouts";
import { PageTitle, Tool, Cta } from "../../components";
import Frontend from "../../assets/data/frontend.json";
import Backend from "../../assets/data/backend.json";
import Database from "../../assets/data/database.json";
import Service from "../../assets/data/services.json";
import Graphics from "../../assets/data/graphics.json";

// styles
import styles from "./tech.module.scss";

//MetaTags
import MetaTags from "react-meta-tags";

const Tech = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <meta
          name="title"
          content="Technologies | Web development in Nepal | Mobile app development in Nepal |Web Design"
        />
        <meta
          name="description"
          content="Firstpaddle uses the latest and trending technology to like Nodejs, Nodejs, Docker, ReactJs, React-Native, MongoDB etc to provide the best result with perfection which makes standout from the rest."
        />
        <link rel="canonical" href="https://www.firstpaddle.com/technologies" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days" />
        <meta name="author" content="Firstpaddle Pvt. Ltd" />
      </MetaTags>
      <Header />
      <PageTitle
        title="Technologies"
        subtitle="We offer only well-recognized and time-tested technologies to support any of your business goals."
      />
      <div className={styles.divider}></div>
      {/* Frontend */}
      <Tool tool={Frontend} />
      <div className={styles.divider}></div>
      {/* Backend */}
      <Tool tool={Backend} />
      <div className={styles.divider}></div>
      {/* Database */}
      <Tool tool={Database} />
      <div className={styles.divider}></div>
      {/* Service */}
      <Tool tool={Service} />
      <div className={styles.divider}></div>
      {/* UI/UX Tools */}
      <Tool tool={Graphics} />
      <div className={styles.divider}></div>
      <Cta />
      <Footer />
    </React.Fragment>
  );
};

export default Tech;
