import React from "react";

// React-bootstrap
import { Row, Col } from "react-bootstrap";

// React router dom
import {useHistory} from 'react-router-dom'

// Local Imports
import { ResponsiveBox } from "../../../components";

// styles
import styles from "../scss/service.module.scss";

const Service = () => {
  const history = useHistory();
  return (
    <ResponsiveBox>
      <h1 className={styles.heading}>Services</h1>
      <p className={styles.subHeading}>
        Our top-notch engineers undergo a rigorous selection process to provide
        first-class custom software development services.{" "}
      </p>

      <div>
        <Row>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <WebIcon />
              </div>
              <h2>Web Development</h2>
              <p>
                While developing websites, we keep three major facts i.e,
                usability, functionality and visualization to initiate web
                design. We work with you to ensure that your digital marketing
                goals are realized to stand out from the rest with the website
                we create.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <MobileIcon />
              </div>
              <h2>Mobile Development</h2>
              <p>
                We use latest cutting edge technologies with creative design and
                user-friendly interfaces for both android and iOS based mobile
                application development facilitating you with business growth
                and brand recognition.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <GraphicsIcon />
              </div>
              <h2>Graphics Design</h2>
              <p>
                Our 
                designs will compel our clients’ customers to visit and revisit
                the website or application very often improving the conversion
                rate, succeeding the social media campaign and application user
                interface.
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.divider}></div>

      <Row className={styles.gridContainer}>
        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h2 className={styles.moreServices}>Looking for other services?</h2>
        </Col>
        <Col xs={12} md={6}>
          <button className={styles.button} onClick={() => history.push('/services')} >Show more </button>
        </Col>
      </Row>

      <div className={styles.divider}></div>
    </ResponsiveBox>
  );
};

const WebIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1">
        <path
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545c-22.632,0-41.045,18.413-41.045,41.045
		v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682s-6.126,13.682-13.682,13.682h-82.09
		c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139c7.556,0,13.682,6.125,13.682,13.682
		s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z M141.547,72.139
		c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S133.991,72.139,141.547,72.139z
		 M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502S73.139,93.376,73.139,85.82
		S79.264,72.139,86.82,72.139z"
        />
        <path
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91c22.632,0,41.045-18.413,41.045-41.045
		V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727
		c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23
		l-55.053,44.045L177.457,348.77z M304.62,214.345l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186
		c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543c2.977-6.945,11.02-10.162,17.965-7.186
		C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727c-5.891,4.714-14.503,3.772-19.23-2.136
		c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043c-5.9-4.72-6.857-13.329-2.137-19.23
		c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2" display="none">
        <g display="inline">
          <g>
            <path
              d="M250,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
				c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
				C270.18,235.854,260.393,238.497,250,238.497z"
            />
            <path
              d="M250,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
				c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
				C270.18,305.35,260.393,307.992,250,307.992z"
            />
            <path
              d="M353.33,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
				c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
				L163.372,18h-16.702c-26.472,0-48.013,21.542-48.013,48.013v367.973c0,26.473,21.542,48.014,48.013,48.014H353.33
				c26.473,0,48.014-21.541,48.014-48.014V66.013C401.344,39.542,379.803,18,353.33,18z M278.721,444.518H221.28
				c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
				S286.227,444.518,278.721,444.518z M310.309,247.683c0,12.923-4.086,24.908-11.033,34.735
				c7.108,10.089,11.033,22.227,11.033,34.76c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308
				c0-12.533,3.923-24.671,11.033-34.76c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76
				c-6.947-9.826-11.033-21.812-11.033-34.735c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308
				c0,12.923-4.086,24.909-11.033,34.735C306.384,223.013,310.309,235.151,310.309,247.683z"
            />
            <cir cx="250" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3" display="none">
        <g display="inline">
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
			V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
			c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
			l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
			C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
			H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
			c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
    </svg>
  );
};

const MobileIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1" display="none">
        <path
          display="inline"
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545
		c-22.632,0-41.045,18.413-41.045,41.045v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682
		s-6.126,13.682-13.682,13.682h-82.09c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139
		c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z
		 M141.547,72.139c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682
		S133.991,72.139,141.547,72.139z M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502
		S73.139,93.376,73.139,85.82S79.264,72.139,86.82,72.139z"
        />
        <path
          display="inline"
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91
		c22.632,0,41.045-18.413,41.045-41.045V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23
		c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727
		c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23l-55.053,44.045L177.457,348.77z M304.62,214.345
		l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543
		c2.977-6.945,11.02-10.162,17.965-7.186C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727
		c-5.891,4.714-14.503,3.772-19.23-2.136c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043
		c-5.9-4.72-6.857-13.329-2.137-19.23c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727
		C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2">
        <g>
          <g>
            <path
              d="M250,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
				c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
				C270.18,235.854,260.393,238.497,250,238.497z"
            />
            <path
              d="M250,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
				c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
				C270.18,305.35,260.393,307.992,250,307.992z"
            />
            <path
              d="M353.33,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
				c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
				L163.372,18h-16.702c-26.472,0-48.013,21.542-48.013,48.013v367.973c0,26.473,21.542,48.014,48.013,48.014H353.33
				c26.473,0,48.014-21.541,48.014-48.014V66.013C401.344,39.542,379.803,18,353.33,18z M278.721,444.518H221.28
				c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
				S286.227,444.518,278.721,444.518z M310.309,247.683c0,12.923-4.086,24.908-11.033,34.735
				c7.108,10.089,11.033,22.227,11.033,34.76c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308
				c0-12.533,3.923-24.671,11.033-34.76c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76
				c-6.947-9.826-11.033-21.812-11.033-34.735c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308
				c0,12.923-4.086,24.909-11.033,34.735C306.384,223.013,310.309,235.151,310.309,247.683z"
            />
            <cir cx="250" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3" display="none">
        <g display="inline">
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
			V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
			c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
			l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
			C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
			H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
			c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
    </svg>
  );
};

const GraphicsIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1" display="none">
        <path
          display="inline"
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545
		c-22.632,0-41.045,18.413-41.045,41.045v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682
		s-6.126,13.682-13.682,13.682h-82.09c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139
		c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z
		 M141.547,72.139c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682
		S133.991,72.139,141.547,72.139z M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502
		S73.139,93.376,73.139,85.82S79.264,72.139,86.82,72.139z"
        />
        <path
          display="inline"
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91
		c22.632,0,41.045-18.413,41.045-41.045V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23
		c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727
		c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23l-55.053,44.045L177.457,348.77z M304.62,214.345
		l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543
		c2.977-6.945,11.02-10.162,17.965-7.186C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727
		c-5.891,4.714-14.503,3.772-19.23-2.136c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043
		c-5.9-4.72-6.857-13.329-2.137-19.23c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727
		C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2" display="none">
        <g display="inline">
          <g>
            <path
              d="M250,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
				c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
				C270.18,235.854,260.393,238.497,250,238.497z"
            />
            <path
              d="M250,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
				c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
				C270.18,305.35,260.393,307.992,250,307.992z"
            />
            <path
              d="M353.33,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
				c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
				L163.372,18h-16.702c-26.472,0-48.013,21.542-48.013,48.013v367.973c0,26.473,21.542,48.014,48.013,48.014H353.33
				c26.473,0,48.014-21.541,48.014-48.014V66.013C401.344,39.542,379.803,18,353.33,18z M278.721,444.518H221.28
				c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
				S286.227,444.518,278.721,444.518z M310.309,247.683c0,12.923-4.086,24.908-11.033,34.735
				c7.108,10.089,11.033,22.227,11.033,34.76c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308
				c0-12.533,3.923-24.671,11.033-34.76c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76
				c-6.947-9.826-11.033-21.812-11.033-34.735c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308
				c0,12.923-4.086,24.909-11.033,34.735C306.384,223.013,310.309,235.151,310.309,247.683z"
            />
            <cir cx="250" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3">
        <g>
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
			V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
			c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
			l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
			C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
			H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
			c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
    </svg>
  );
};

export default Service;
