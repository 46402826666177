// React
import React from "react";

// Local Imports
import { Header, Footer } from "../../layouts";
import { Cta, PageTitle } from "../../components";
import { Story, Value, Team } from "./js";

// styles
import styles from "./about.module.scss";

//MetaTags
import MetaTags from "react-meta-tags";

const About = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <meta
          name="title"
          content="About | Web development in Nepal | Mobile app development in Nepal | Web Design"
        />
        <meta
          name="description"
          content="Firstpaddle is one of the leading web and mobile app development company in Nepal founded by the team of enthusiastic, dedicated, deserving 4 BE students in Electronics and Communication from Pulchowk College, Pulchowk with an aim to  turn your idea into reality. We believe that our success resides in the success and satisfaction of our client. "
        />
        <link rel="canonical" href="https://www.firstpaddle.com/about" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days" />
        <meta name="author" content="Firstpaddle Pvt. Ltd" />
      </MetaTags>
      <Header />
      <PageTitle
        title="About Us"
        subtitle="Our team consists of hardworking, dedicated and quick-witted people. We know how to make the best decisions and are able to put our experience to work for you. Radical honesty is one of our key values we practice whilst working with our clients."
      />
      <div className={styles.divider}></div>
      <Story />
      <div className={styles.dividerHalf}></div>
      <Value />
      <div className={styles.divider}></div>
      {/* <Team />
      <div className={styles.divider}></div> */}
      <Cta />
      <Footer />
    </React.Fragment>
  );
};

export default About;
