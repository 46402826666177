// React
import React from "react";

// React-bootstrap
import { Container, Row, Col } from "react-bootstrap";

// React router dom
import {useHistory} from 'react-router-dom'

// React Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faNodeJs,
  faLaravel,
  faWordpress,
  faAws,
} from "@fortawesome/free-brands-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

// Local Imports
import { ResponsiveBox } from "../../../components";

// styles
import styles from "../scss/tech.module.scss";

const Tech = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className={styles.cover}>
        <div className={styles.divider}></div>
        <ResponsiveBox>
          <h1 className={styles.heading}>Technologies</h1>
          <p className={styles.subHeading}>
            We offer only well-recognized and time-tested technologies to
            support any of your business goals.{" "}
          </p>

          <Container>
            <Row>
              <Col xs={4} md={2}>
                <div className={styles.iconCover}>
                  <FontAwesomeIcon icon={faReact} className={styles.icon} />
                  <h6>React </h6>
                </div>
              </Col>
              <Col xs={4} md={2}>
                <div className={styles.iconCover}>
                  <FontAwesomeIcon icon={faNodeJs} className={styles.icon} />
                  <h6> Node </h6>
                </div>
              </Col>
              <Col xs={4} md={2}>
                <div className={styles.iconCover}>
                  <FontAwesomeIcon icon={faLaravel} className={styles.icon} />
                  <h6> Laravel </h6>
                </div>
              </Col>
              <Col xs={4} md={2}>
                <div className={styles.iconCover}>
                  <FontAwesomeIcon icon={faWordpress} className={styles.icon} />
                  <h6> Wordpress </h6>
                </div>
              </Col>
              <Col xs={4} md={2}>
                <div className={styles.iconCover}>
                  <FontAwesomeIcon icon={faAws} className={styles.icon} />
                  <h6> AWS </h6>
                </div>
              </Col>
              <Col xs={4} md={2}>
                <div className={styles.iconCover}>
                  <FontAwesomeIcon icon={faReact} className={styles.icon} />
                  <h6>React Native</h6>
                </div>
              </Col>
            </Row>
          </Container>
          <div className={styles.flexContainer} onClick={() => history.push('/technologies')}>
            <h6 className={styles.more}>More </h6>
            <FontAwesomeIcon icon={faAngleRight} className={styles.moreIcon} />
          </div>
        </ResponsiveBox>
        <div className={styles.divider}></div>
      </div>
    </React.Fragment>
  );
};

export default Tech;
