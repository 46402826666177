// React
import React from "react";

// React router
import { useHistory } from "react-router-dom";

// React-bootstrap
import { Container, Row, Col } from "react-bootstrap";

// React Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

// Local Imports
import { ResponsiveBox } from "../../../components";
import Project1 from "../../../assets/images/one.jpg";
import Project2 from "../../../assets/images/two.jpg"

// styles
import styles from "../scss/project.module.scss";

const Project = (props) => {
  const history = useHistory();

  let projects = [1, 2];

  // const displayProjects = projects.map((item, index) => {
  //   return (
  //     <Col xs={12} lg={6} key={index}>
  //       <div className={styles.outerCover1}>
  //         <div className={`${styles.cover} ${styles.cover1}`}>
  //           <a href="https://unitysupport.org/"><div className={styles.project}></div></a>
  //           <div className={styles.content}>
  //             <h4>UnitySupport</h4>
  //             <div className={styles.flexContainer}>
  //               <h6>Blood donation web app</h6>
  //               <h6
  //                 className={styles.moreTxt}
  //                 onClick={() => history.push("/project/1")}
  //               >
  //                 Learn More
  //               </h6>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Col>
  //   );
  // });

  return (
    <ResponsiveBox>
      <h1 className={styles.heading}>Recent Projects</h1>
      <p className={styles.subHeading}>
        We have a  track record of building high quality projects. 
      </p>

      <Row className={`gutter-5`}>
        {/* {displayProjects} */}
        <Col xs={12} lg={6}>
        <div className={styles.outerCover1}>
          <div className={`${styles.cover} ${styles.cover1}`}>
            <a href="https://unitysupport.org/" target="_blank">
              <div className={styles.project} style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url(${Project1})`}}></div>
              </a>
            <div className={styles.content}>
              <h4>UnitySupport</h4>
              <div className={styles.flexContainer}>
                <h6>Blood donation web app</h6>
                <a
                  className={styles.moreTxt}
                  href="https://unitysupport.org/"
                  target="_blank"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} lg={6}>
        <div className={styles.outerCover1}>
          <div className={`${styles.cover} ${styles.cover1}`}>
            <a href="https://samriddhi.firstpaddle.com/" target="_blank"><div className={styles.project} style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url(${Project2})`}}></div></a>
            <div className={styles.content}>
              <h4>Samriddhi</h4>
              <div className={styles.flexContainer}>
                <h6>Hotel Booking site</h6>
                <a
                  className={styles.moreTxt}
                  target="_blank"
                  href="https://samriddhi.firstpaddle.com/"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </Col>
      </Row>
      <div className={styles.divider}></div>
      {/* <Row className={styles.gridContainer}>
        <Col xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <h2 className={styles.moreProjects}>Interested in other projects?</h2>
        </Col>
        <Col xs={12} md={6}>
          <button className={styles.button}>Show more </button>
        </Col>
      </Row>
      <div className={styles.divider}></div> */}
    </ResponsiveBox>
  );
};

export default Project;
