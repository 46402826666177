// React
import React, { useState } from "react";

// React-bootstrap
import { Row, Col, Form, Modal } from "react-bootstrap";

// Local Imports
import { Header, Footer } from "../../layouts";
import { Cta, ResponsiveBox } from "../../components";

// styles
import styles from "./contact.module.scss";

//MetaTags
import MetaTags from "react-meta-tags";

const Contact = () => {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [input, setInput] = useState({ name: "", email: "", message: "" });
  const [modalMsg, setModalMsg] = useState();
  const [btnTxt, setBtnTxt] = useState("Submit");

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();

      setBtnTxt("Please Wait...");

      fetch("http://110.34.31.28:4505/contactUs", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          credentials: "include",
        },
        body: JSON.stringify(input),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          console.log(response);
          setBtnTxt("Submit");
          if (response.status == "success") {
            setModalMsg("We got your message.");
            setShow(true);
            setInput({ name: "", email: "", message: "" });
            form.reset();
            setValidated(false);
          } else {
            setModalMsg("Oops. We are having some trouble. Try again later.");
            setShow(true);
            setValidated(true);
          }
        })
        .catch((e) => {
          console.log("Fetch Error", e);
          setBtnTxt("Submit");
          setModalMsg("Oops. We are having some trouble. Try again later.");
          setShow(true);
          setValidated(true);
        });
    }
    setValidated(true);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <meta
          name="title"
          content="Contact Us | Web development in Nepal | Mobile app development in Nepal | Web Design"
        />
        <meta
          name="description"
          content="Firstpaddle is located in Shankhamul, Lalitpur. The details of our contacts are provided below. One can call us, email us or message us to collaborate with us to turn your idea into reality and make dreams come true."
        />
        <link rel="canonical" href="https://www.firstpaddle.com/contact" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days" />
        <meta name="author" content="Firstpaddle Pvt. Ltd" />
      </MetaTags>
      <Header />

      <div className={styles.divider}></div>

      <ResponsiveBox>
        <Row>
          <Col xs={12} lg={6}>
            <div className={styles.info}>
              <h2 className={styles.heading}>Let's Get In Touch</h2>
              <p className={styles.subHeading}>
                Want to discuss your project or partnership? We're always ready
                to help.
              </p>

              <div className={styles.txtContainer}>
                <h6>Call Us</h6>
                <h4>+977 9867137105</h4>
                <h4>+977 9843552226</h4>
              </div>

              <div className={styles.txtContainer}>
                <h6>Email</h6>
                <h4>info@firstpaddle.com</h4>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className={styles.form}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    onChange={(e) =>
                      setInput({ ...input, name: e.target.value })
                    }
                    className={styles.input}
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={styles.feedbackTxt}
                  >
                    Please enter your name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    onChange={(e) =>
                      setInput({ ...input, email: e.target.value })
                    }
                    className={styles.input}
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={styles.feedbackTxt}
                  >
                    Please enter your email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    placeholder="Enter your message"
                    onChange={(e) =>
                      setInput({ ...input, message: e.target.value })
                    }
                    className={styles.input}
                    required
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={styles.feedbackTxt}
                  >
                    Please write your message.
                  </Form.Control.Feedback>
                </Form.Group>

                <button className={styles.button} type="submit">
                  {btnTxt}
                </button>
              </Form>
            </div>
          </Col>
        </Row>
        <Modal show={show} onHide={() => setShow(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className={styles.modalTitle}>
              Hello there !!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>{modalMsg}</Modal.Body>
        </Modal>
      </ResponsiveBox>
      <div className={styles.divider}></div>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
