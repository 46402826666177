// React
import React from "react";

// Local Imports
import { Header, Footer } from "../../layouts";
import { Cta } from "../../components";
import { LandingImage, Service, Tech, Project } from "./js";
import projects from "../../assets/data/featuredProjects.json";

//MetaTags
import MetaTags from "react-meta-tags";

// Styles
import styles from "./home.module.scss";

const Home = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <meta
          name="title"
          content="Web development in Nepal | Mobile app development in Nepal |Web Design"
        />

        <meta
          name="description"
          content="Firstpaddle is one of the leading web and mobile app development company in Nepal. It mainly focuses in excellent services and high ROI for its clients."
        />
        <link rel="canonical" href="https://www.firstpaddle.com" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days" />
        <meta name="author" content="Firstpaddle Pvt. Ltd" />

        <meta property="fb:app_id" content="your_app_id" />
        <meta
          property="og:title"
          content="Firstpaddle | Web Design in Nepal | Web Development in Nepal | Mobile app development in Nepal"
        />
        <meta
          property="og:description"
          content="Firstpaddle is one of the leading web and mobile app development company in Nepal. It mainly focuses in excellent services and high ROI for its clients."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.firstpaddle.com" />
        <meta property="og:site_name" content="Firstpaddle,Pvt.,Ltd." />

        <meta
          name="twitter:site"
          content="Firstpaddle-web and mobile app development Company"
        />
        <meta
          name="twitter:title"
          content="Firstpaddle | Web Design in Nepal | Web Development in Nepal | Mobile app development in Nepal "
        />
        <meta
          name="twitter:description"
          content=" Firstpaddle is one of the leading web and mobile app development company in Nepal. It mainly focuses in excellent services and high ROI for its clients."
        />
        <meta name="twitter:image" content=" " />
        <meta name="twitter:card" content="summary_large_image" />
      </MetaTags>
      <Header />

      <LandingImage />

      <div className={styles.divider}></div>

      <Service />

      <Tech />

      <div className={styles.divider}></div>

      <Project projects={projects} />

      <Cta />

      <Footer />
    </React.Fragment>
  );
};

export default Home;
