// React
import React from 'react';

// React Bootstrap
import { Row, Col } from 'react-bootstrap';

// Local Imports
import { ResponsiveBox } from '../../../components';
import { dateFormatter } from '../../../utils';

// Styles
import styles from '../scss/blogList.module.scss';

const BlogList = ({ blogs, selectBlog }) => {
  return (
    <ResponsiveBox>
      <h1 className={styles.heading}>Thoughts & Ideas</h1>
      <div className={styles.dividerHalf}></div>

      <Row>
        {blogs.map((item, index) => (
          <Col
            xs={12}
            sm={6}
            lg={4}
            className={styles.gridCol}
            key={index}
            onClick={() => selectBlog(item.slug)}
          >
            <div className={styles.blog}>
              <div className={styles.imageCover}>
                <img
                  className={styles.image}
                  src={item.metadata && item.metadata.image.url}
                  alt='blog-image'
                />
              </div>
              <div className={styles.contextCover}>
                <p className={styles.author}>
                  {item.metadata && item.metadata.author},{' '}
                </p>
                <p className={styles.date}>
                  {dateFormatter(item.metadata && item.metadata.published_date)}
                </p>
              </div>
              <h6 className={styles.title}>
                {item.title}
              </h6>
            </div>
          </Col>
        ))}
      </Row>
    </ResponsiveBox>
  );
};

export default BlogList;
