// React
import React, { useState } from "react";

// React Router
import { NavLink, withRouter } from "react-router-dom";

// Local Imports
import { ResponsiveBox } from "../../components";
import logo from "../../assets/images/logo.png";

// styles
import styles from "./header.module.scss";

const Header = () => {
  const [overlayNav, setOverlayNav] = useState(false);

  return (
    <header>
      {/* desktop navbar */}
      <nav>
        <ResponsiveBox className={styles.flexContainer}>
          <a href="https://www.firstpaddle.com/" style={{textDecoration: 'none', outline: 'none'}}>
          <img src={logo} className={styles.logo} alt="logo" />
          </a>
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                className={styles.navLink}
                activeClassName={styles.active}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/services"
                className={styles.navLink}
                activeClassName={styles.active}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/projects"
                className={styles.navLink}
                activeClassName={styles.active}
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/technologies"
                className={styles.navLink}
                activeClassName={styles.active}
              >
                Technologies
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/blogs"
                className={styles.navLink}
                activeClassName={styles.active}
              >
                Blogs
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/about"
                className={styles.navLink}
                activeClassName={styles.active}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/contact"
                className={styles.navLink}
                activeClassName={styles.active}
              >
                Contact us
              </NavLink>
            </li>
          </ul>
          <div
            className={
              !overlayNav ? `${styles.burger}` : `${styles.burgerClose}`
            }
            onClick={() => setOverlayNav(!overlayNav)}
          >
            <div className={styles.line1}></div>
            <div className={styles.line2}></div>
            <div className={styles.line3}></div>
          </div>
        </ResponsiveBox>
      </nav>

      {/* mobile overlay navbar */}
      <div
        className={
          overlayNav ? `${styles.overlayNav}` : `${styles.overlayNavClose}`
        }
      >
        <ResponsiveBox>
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                className={styles.overlayNavLink}
                activeClassName={styles.overlayActiveNavLink}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/services"
                className={styles.overlayNavLink}
                activeClassName={styles.overlayActiveNavLink}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/projects"
                className={styles.overlayNavLink}
                activeClassName={styles.overlayActiveNavLink}
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/technologies"
                className={styles.overlayNavLink}
                activeClassName={styles.overlayActiveNavLink}
              >
                Technologies
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/about"
                className={styles.overlayNavLink}
                activeClassName={styles.overlayActiveNavLink}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/contact"
                className={styles.overlayNavLink}
                activeClassName={styles.overlayActiveNavLink}
              >
                Contact us
              </NavLink>
            </li>
          </ul>
        </ResponsiveBox>
      </div>
    </header>
  );
};

export default Header;
