// React
import React from "react";

// React bootstrap
import { Row, Col } from "react-bootstrap";

// Local Imports
import { PageTitle, ResponsiveBox, Tool, Cta } from "../../../components";

// styles
import styles from "../scss/content.module.scss";

const Content = () => {
  return (
    <>
      {/* Features Implemented */}
      <ResponsiveBox className={styles.box}>
        <div className={styles.divider}></div>
        <h2 className={styles.heading}>Features Implemented</h2>
        <p className={styles.body}>
          To meet the requirements, we applied Angular and Node.js technologies
          and built a complex customized educational portal with multiple user
          roles. Based on the achieved results, mentors are able to post
          information about regular courses or a set of flexible classes with
          the number of students in a class. Teachers can book preferred
          classes. Learners just choose a course they are interested in,
          subscribe, and pay online for the services. To proceed with a lesson,
          one should log in to their personal profile on the platform or use a
          link, which is sent to an email address in advance. The courses on the
          platform are divided into two types — Regular and Flexible. If
          students opt for a Regular course, they are pre-registered for each
          class in the course. Flexible courses allow students to visit any
          lesson, therefore there’s no possibility to take the whole course at
          once. The students need to book a place for each lesson.
        </p>
      </ResponsiveBox>

      <div className={styles.divider}></div>

      {/* Image 1 */}
      <div className={styles.imgCover}>
        <div className={styles.dividerHalf}></div>
        <ResponsiveBox className={styles.box}>
          <img
            src="https://otakoyi.com/_cases/enlead/an.gif"
            className={styles.img}
            alt="image-gif"
          />
        </ResponsiveBox>
        <div className={styles.dividerHalf}></div>
      </div>

      <div className={styles.divider}></div>

      {/* Values Delivered */}
      <ResponsiveBox className={styles.box}>
        <h2 className={styles.heading}>Values Delivered</h2>
        <p className={styles.body}>
          Enlead’s idea was transformed into the online platform that connects
          native-speaking teachers to learners globally. The implementation of
          an intuitive user-centered design for each user type gave us a
          possibility to achieve a much more effective interaction between the
          users. We helped to build the platform that provides online live
          classes (small groups of no more than 6 people) with two main types of
          learning programs special courses and flexible classes. In order for
          students to achieve their goals faster, there is a possibility to set
          up an individual learning plan with a personal coach.
        </p>
      </ResponsiveBox>

      <div className={styles.divider}></div>

      {/* Values Delivered */}
      <ResponsiveBox className={styles.box}>
        <h2 className={styles.heading}>Experience Gained</h2>
        <p className={styles.body}>
          OTAKOYI designed and developed a whole new user experience for the
          client’s product, which contains over 200 unique mockups. We also
          helped to design a corporate identity that the client could use for
          the website. To build an easy-to-use system a combination of top
          technologies was implemented, including custom back-end solutions and
          integrations with communication and payment platforms. Moreover,
          working on the Enlead project we broadened our knowledge of the online
          learning market and improved our educational industry expertise.
        </p>
      </ResponsiveBox>

      <div className={styles.divider}></div>

      {/* Image 2 */}
      <div className={styles.imgCover}>
        <div className={styles.dividerHalf}></div>
        <ResponsiveBox className={styles.box}>
          <img
            src="https://otakoyi.com/_cases/enlead/an.gif"
            className={styles.img}
            alt="image-gif"
          />
        </ResponsiveBox>
        <div className={styles.dividerHalf}></div>
      </div>

      <div className={styles.largeDivider}></div>

      <ResponsiveBox>

      <h1 className={styles.heading}>Similar Projects</h1>

      <Row className={`gutter-5`}>
        <Col xs={12} lg={6}>
          <div className={styles.outerCover1}>
            <div className={`${styles.cover} ${styles.cover1}`}>
              <div className={styles.project}>
              </div>
              <div className={styles.content}>
                      <h4>Let's Chat</h4>
                      <div className={styles.flexContainer}>
                      <h6>A chat app</h6>
                      <h6 className={styles.moreTxt}>Learn More</h6>
                      </div>
                  </div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className={styles.outerCover2}>
            <div className={`${styles.cover} ${styles.cover2}`}>
              <div className={styles.project}></div>
              <div className={styles.content}>
                      <h4>Samriddhi</h4>
                      <div className={styles.flexContainer}>
                      <h6>Hotel Booking Site</h6>
                      <h6 className={styles.moreTxt}>Learn More</h6>
                      </div>
                  </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className={styles.largeDivider}></div>
      
      </ResponsiveBox>

    </>
  );
};

export default Content;
