// React
import React from "react";

// React bootstrap
import { Row, Col } from "react-bootstrap";

// Local Imports
import ResponsiveBox from "../box/responsiveBox";

// Styles
import styles from "./tool.module.scss";

const Tool = (props) => {
  
  const showTools = props.tool.tools.map((item, index) => {
    return (
      <ShowTool
      key={index}
          tool={item.tool}
          image={item.image}
          alt={item.alt}
        />
    );
  })

  return (
    <ResponsiveBox>
      <h2 className={styles.heading}>{props.tool.title}</h2>
      <p className={styles.subHeading}>
       {props.tool.subtitle}
      </p>

      <Row>
        {showTools}
      </Row>
    </ResponsiveBox>
  );
};

const ShowTool = (props) => {
  return (
    <Col xs={4} md={3} lg={2}>
      <div className={styles.cover}>
        <img src={props.image} alt={props.alt} />
        <h6>{props.tool}</h6>
      </div>
    </Col>
  );
};

export default Tool;
