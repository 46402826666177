// React
import React from "react";

// React-bootstrap
import { Row, Col } from "react-bootstrap";

// Local Imports
import { Header, Footer } from "../../layouts";
import { PageTitle, Cta, ResponsiveBox } from "../../components";

// styles
import styles from "./service.module.scss";

//MetaTags
import MetaTags from "react-meta-tags";

const Service = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <meta
          name="title"
          content="Services | Web Design and Development in Nepal | Mobile application development | Web Design in Nepal"
        />
        <meta
          name="description"
          content="We offer variety of services to our clients related to web design and development, mobile application development, Saas, desktop applications and digital marketing with perfection."
        />
        <link rel="canonical" href="https://www.firstpaddle.com/services" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days" />
        <meta name="author" content="Firstpaddle Pvt. Ltd" />
      </MetaTags>
      <Header />
      <PageTitle
        title="Services"
        subtitle="FIRST PADDLE is your reliable partner for any business-changing solutions."
      />
      <div className={styles.divider}></div>

      <ResponsiveBox>
        <h1 className={styles.heading}>We can help you with</h1>
        <p className={styles.subHeading}>
          Creative designs and user-friendly interfaces can contribute to your
          business success in web and mobile.{" "}
        </p>
        <Row>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <WebIcon />
              </div>
              <h2>Web Design & Development</h2>
              <p>
                While developing websites, we keep three major facts i.e,
                usability, functionality and visualization to initiate web
                design. We work with you to ensure that your digital marketing
                goals are realized to stand out from the rest with the website
                we create.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <MobileIcon />
              </div>
              <h2>Mobile Development</h2>
              <p>
                We use latest cutting edge technologies with creative design and
                user-friendly interfaces for both android and iOS based mobile
                application development facilitating you with business growth
                and brand recognition.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <GraphicsIcon />
              </div>
              <h2>Graphics Design</h2>
              <p>
                Our 
                designs will compel our clients’ customers to visit and revisit
                the website or application very often improving the conversion
                rate, succeeding the social media campaign and application user
                interface.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <EcommerceIcon />
              </div>
              <h2>Ecommerce</h2>
              <p>
                We are ready to offer you advanced e-commerce professional
                solutions for better business operations. We provide you the
                custom eCommerce solution to your business that support you to
                securely manage, organize and sell your products and services
                online.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <SaasIcon />
              </div>
              <h2>SaaS</h2>
              <p>
                With the popularity gaining across the world wide as the next
                best alternative to the on-premise hardware and software
                development, Saas has a wide range of benefits for your modern
                business model.It provides flexiblity, scalablity and easiness
                in use to your business requirement.
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            {" "}
            <div className={styles.cover}>
              <div className={styles.icon}>
                <DesktopIcon />
              </div>
              <h2>Desktop Apps</h2>
              <p>
                Though cloud computing and web based application are gaining
                popularity these days, but when it comes t robustness, desktop
                applications are extremely useful and popular till today. We
                offer platform-specific and hybrid desktop apps to help your
                business grow in the right direction.
              </p>
            </div>
          </Col>
        </Row>
      </ResponsiveBox>
      <div className={styles.divider}></div>
      <Cta />
      <Footer />
    </React.Fragment>
  );
};

const WebIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1">
        <path
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545c-22.632,0-41.045,18.413-41.045,41.045
          v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682s-6.126,13.682-13.682,13.682h-82.09
          c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139c7.556,0,13.682,6.125,13.682,13.682
          s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z M141.547,72.139
          c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S133.991,72.139,141.547,72.139z
           M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502S73.139,93.376,73.139,85.82
          S79.264,72.139,86.82,72.139z"
        />
        <path
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91c22.632,0,41.045-18.413,41.045-41.045
          V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727
          c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23
          l-55.053,44.045L177.457,348.77z M304.62,214.345l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186
          c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543c2.977-6.945,11.02-10.162,17.965-7.186
          C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727c-5.891,4.714-14.503,3.772-19.23-2.136
          c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043c-5.9-4.72-6.857-13.329-2.137-19.23
          c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2" display="none">
        <g display="inline">
          <g>
            <path
              d="M250,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
                  c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
                  C270.18,235.854,260.393,238.497,250,238.497z"
            />
            <path
              d="M250,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
                  c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
                  C270.18,305.35,260.393,307.992,250,307.992z"
            />
            <path
              d="M353.33,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
                  c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
                  L163.372,18h-16.702c-26.472,0-48.013,21.542-48.013,48.013v367.973c0,26.473,21.542,48.014,48.013,48.014H353.33
                  c26.473,0,48.014-21.541,48.014-48.014V66.013C401.344,39.542,379.803,18,353.33,18z M278.721,444.518H221.28
                  c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
                  S286.227,444.518,278.721,444.518z M310.309,247.683c0,12.923-4.086,24.908-11.033,34.735
                  c7.108,10.089,11.033,22.227,11.033,34.76c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308
                  c0-12.533,3.923-24.671,11.033-34.76c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76
                  c-6.947-9.826-11.033-21.812-11.033-34.735c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308
                  c0,12.923-4.086,24.909-11.033,34.735C306.384,223.013,310.309,235.151,310.309,247.683z"
            />
            <cir cx="250" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3" display="none">
        <g display="inline">
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
              V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
              c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
              l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
              C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
              H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
              c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
    </svg>
  );
};

const MobileIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1" display="none">
        <path
          display="inline"
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545
		c-22.632,0-41.045,18.413-41.045,41.045v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682
		s-6.126,13.682-13.682,13.682h-82.09c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139
		c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z
		 M141.547,72.139c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682
		S133.991,72.139,141.547,72.139z M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502
		S73.139,93.376,73.139,85.82S79.264,72.139,86.82,72.139z"
        />
        <path
          display="inline"
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91
		c22.632,0,41.045-18.413,41.045-41.045V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23
		c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727
		c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23l-55.053,44.045L177.457,348.77z M304.62,214.345
		l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543
		c2.977-6.945,11.02-10.162,17.965-7.186C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727
		c-5.891,4.714-14.503,3.772-19.23-2.136c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043
		c-5.9-4.72-6.857-13.329-2.137-19.23c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727
		C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2">
        <g>
          <g>
            <path
              d="M165.291,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
				c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
				C185.471,235.854,175.684,238.497,165.291,238.497z"
            />
            <path
              d="M165.291,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
				c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
				C185.471,305.35,175.684,307.992,165.291,307.992z"
            />
            <path
              d="M268.621,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
				c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
				L78.663,18H61.96c-26.472,0-48.013,21.542-48.013,48.013v367.973C13.947,460.459,35.489,482,61.96,482h206.661
				c26.473,0,48.014-21.541,48.014-48.014V66.013C316.635,39.542,295.094,18,268.621,18z M194.012,444.518h-57.441
				c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
				S201.518,444.518,194.012,444.518z M225.6,247.683c0,12.923-4.086,24.908-11.033,34.735c7.108,10.089,11.033,22.227,11.033,34.76
				c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308c0-12.533,3.923-24.671,11.033-34.76
				c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76c-6.947-9.826-11.033-21.812-11.033-34.735
				c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308c0,12.923-4.086,24.909-11.033,34.735
				C221.675,223.013,225.6,235.151,225.6,247.683z"
            />
            <cir cx="165.291" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3" display="none">
        <g display="inline">
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
			V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
			c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
			l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
			C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
			H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
			c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
      <g id="Layer_4" display="none">
        <g display="inline">
          <path
            d="M298.293,286.764c0,3.658-2.979,6.631-6.629,6.631H171.92c-3.665,0-6.629,2.965-6.629,6.625
			c0,3.666,2.964,6.627,6.629,6.627h119.744c10.964,0,19.884-8.918,19.884-19.883c0-8.156-4.945-15.166-11.986-18.227
			l23.934-107.702h34.89c3.662,0,6.627-2.963,6.627-6.628c0-3.663-2.965-6.628-6.627-6.628h-40.209
			c-3.107,0-5.801,2.156-6.474,5.192l-4.737,21.319H145.408c-2.084,0-4.039,0.979-5.295,2.635c-1.254,1.663-1.645,3.811-1.076,5.812
			l26.513,92.792c0.804,2.846,3.404,4.807,6.37,4.807h119.744C295.314,280.137,298.293,283.107,298.293,286.764z"
          />
          <path
            d="M278.406,306.646c-10.963,0-19.883,8.924-19.883,19.887c0,10.965,8.92,19.883,19.883,19.883
			c10.966,0,19.887-8.918,19.887-19.883C298.293,315.57,289.372,306.646,278.406,306.646z"
          />
          <path
            d="M185.176,306.646c-10.966,0-19.885,8.924-19.885,19.887c0,10.965,8.919,19.883,19.885,19.883
			c10.963,0,19.883-8.918,19.883-19.883C205.059,315.57,196.139,306.646,185.176,306.646z"
          />
        </g>
        <path
          display="inline"
          d="M290.894,133.084V44.288c0-17.69-14.461-32.118-32.118-32.118H46.688
		c-17.689,0-32.118,14.428-32.118,32.118v411.435c0,17.689,14.429,32.107,32.118,32.107h212.077
		c17.668,0,32.107-14.418,32.107-32.107V363.39h-22.333v65.017H36.894V63.107h231.646v69.978H290.894z M152.71,439.637
		c8.887,0,16.096,7.199,16.096,16.053c-0.021,8.888-7.209,16.096-16.096,16.096c-8.866,0-16.064-7.208-16.064-16.096
		C136.646,446.836,143.844,439.637,152.71,439.637z M186.633,43.095h-67.846c-2.143,0-3.874-1.763-3.874-3.884
		c0-2.153,1.731-3.853,3.874-3.853h67.846c2.143,0,3.884,1.699,3.884,3.853C190.517,41.332,188.776,43.095,186.633,43.095z"
        />
      </g>
    </svg>
  );
};

const GraphicsIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1" display="none">
        <path
          display="inline"
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545
          c-22.632,0-41.045,18.413-41.045,41.045v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682
          s-6.126,13.682-13.682,13.682h-82.09c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139
          c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z
           M141.547,72.139c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682
          S133.991,72.139,141.547,72.139z M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502
          S73.139,93.376,73.139,85.82S79.264,72.139,86.82,72.139z"
        />
        <path
          display="inline"
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91
          c22.632,0,41.045-18.413,41.045-41.045V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23
          c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727
          c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23l-55.053,44.045L177.457,348.77z M304.62,214.345
          l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543
          c2.977-6.945,11.02-10.162,17.965-7.186C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727
          c-5.891,4.714-14.503,3.772-19.23-2.136c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043
          c-5.9-4.72-6.857-13.329-2.137-19.23c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727
          C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2" display="none">
        <g display="inline">
          <g>
            <path
              d="M250,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
                  c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
                  C270.18,235.854,260.393,238.497,250,238.497z"
            />
            <path
              d="M250,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
                  c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
                  C270.18,305.35,260.393,307.992,250,307.992z"
            />
            <path
              d="M353.33,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
                  c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
                  L163.372,18h-16.702c-26.472,0-48.013,21.542-48.013,48.013v367.973c0,26.473,21.542,48.014,48.013,48.014H353.33
                  c26.473,0,48.014-21.541,48.014-48.014V66.013C401.344,39.542,379.803,18,353.33,18z M278.721,444.518H221.28
                  c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
                  S286.227,444.518,278.721,444.518z M310.309,247.683c0,12.923-4.086,24.908-11.033,34.735
                  c7.108,10.089,11.033,22.227,11.033,34.76c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308
                  c0-12.533,3.923-24.671,11.033-34.76c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76
                  c-6.947-9.826-11.033-21.812-11.033-34.735c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308
                  c0,12.923-4.086,24.909-11.033,34.735C306.384,223.013,310.309,235.151,310.309,247.683z"
            />
            <cir cx="250" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3">
        <g>
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
              V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
              c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
              l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
              C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
              H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
              c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
    </svg>
  );
};

const EcommerceIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1" display="none">
        <path
          display="inline"
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545
		c-22.632,0-41.045,18.413-41.045,41.045v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682
		s-6.126,13.682-13.682,13.682h-82.09c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139
		c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z
		 M141.547,72.139c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682
		S133.991,72.139,141.547,72.139z M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502
		S73.139,93.376,73.139,85.82S79.264,72.139,86.82,72.139z"
        />
        <path
          display="inline"
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91
		c22.632,0,41.045-18.413,41.045-41.045V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23
		c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727
		c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23l-55.053,44.045L177.457,348.77z M304.62,214.345
		l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543
		c2.977-6.945,11.02-10.162,17.965-7.186C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727
		c-5.891,4.714-14.503,3.772-19.23-2.136c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043
		c-5.9-4.72-6.857-13.329-2.137-19.23c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727
		C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2" display="none">
        <g display="inline">
          <g>
            <path
              d="M250,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
				c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
				C270.18,235.854,260.393,238.497,250,238.497z"
            />
            <path
              d="M250,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
				c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
				C270.18,305.35,260.393,307.992,250,307.992z"
            />
            <path
              d="M353.33,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
				c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
				L163.372,18h-16.702c-26.472,0-48.013,21.542-48.013,48.013v367.973c0,26.473,21.542,48.014,48.013,48.014H353.33
				c26.473,0,48.014-21.541,48.014-48.014V66.013C401.344,39.542,379.803,18,353.33,18z M278.721,444.518H221.28
				c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
				S286.227,444.518,278.721,444.518z M310.309,247.683c0,12.923-4.086,24.908-11.033,34.735
				c7.108,10.089,11.033,22.227,11.033,34.76c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308
				c0-12.533,3.923-24.671,11.033-34.76c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76
				c-6.947-9.826-11.033-21.812-11.033-34.735c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308
				c0,12.923-4.086,24.909-11.033,34.735C306.384,223.013,310.309,235.151,310.309,247.683z"
            />
            <cir cx="250" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3" display="none">
        <g display="inline">
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
			V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
			c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
			l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
			C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
			H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
			c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
      <g id="Layer_4">
        <g>
          <path
            d="M298.293,286.764c0,3.658-2.979,6.631-6.629,6.631H171.92c-3.665,0-6.629,2.965-6.629,6.625
			c0,3.666,2.964,6.627,6.629,6.627h119.744c10.964,0,19.884-8.918,19.884-19.883c0-8.156-4.945-15.166-11.986-18.227
			l23.934-107.702h34.89c3.662,0,6.627-2.963,6.627-6.628c0-3.663-2.965-6.628-6.627-6.628h-40.209
			c-3.107,0-5.801,2.156-6.474,5.192l-4.737,21.319H145.408c-2.084,0-4.039,0.979-5.295,2.635c-1.254,1.663-1.645,3.811-1.076,5.812
			l26.513,92.792c0.804,2.846,3.404,4.807,6.37,4.807h119.744C295.314,280.137,298.293,283.107,298.293,286.764z"
          />
          <path
            d="M278.406,306.646c-10.963,0-19.883,8.924-19.883,19.887c0,10.965,8.92,19.883,19.883,19.883
			c10.966,0,19.887-8.918,19.887-19.883C298.293,315.57,289.372,306.646,278.406,306.646z"
          />
          <path
            d="M185.176,306.646c-10.966,0-19.885,8.924-19.885,19.887c0,10.965,8.919,19.883,19.885,19.883
			c10.963,0,19.883-8.918,19.883-19.883C205.059,315.57,196.139,306.646,185.176,306.646z"
          />
        </g>
        <path
          d="M290.894,133.084V44.288c0-17.69-14.461-32.118-32.118-32.118H46.688
		c-17.689,0-32.118,14.428-32.118,32.118v411.435c0,17.689,14.429,32.107,32.118,32.107h212.077
		c17.668,0,32.107-14.418,32.107-32.107V363.39h-22.333v65.017H36.894V63.107h231.646v69.978H290.894z M152.71,439.637
		c8.887,0,16.096,7.199,16.096,16.053c-0.021,8.888-7.209,16.096-16.096,16.096c-8.866,0-16.064-7.208-16.064-16.096
		C136.646,446.836,143.844,439.637,152.71,439.637z M186.633,43.095h-67.846c-2.143,0-3.874-1.763-3.874-3.884
		c0-2.153,1.731-3.853,3.874-3.853h67.846c2.143,0,3.884,1.699,3.884,3.853C190.517,41.332,188.776,43.095,186.633,43.095z"
        />
      </g>
    </svg>
  );
};

const SaasIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1" display="none">
        <path
          display="inline"
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545
		c-22.632,0-41.045,18.413-41.045,41.045v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682
		s-6.126,13.682-13.682,13.682h-82.09c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139
		c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z
		 M141.547,72.139c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682
		S133.991,72.139,141.547,72.139z M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502
		S73.139,93.376,73.139,85.82S79.264,72.139,86.82,72.139z"
        />
        <path
          display="inline"
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91
		c22.632,0,41.045-18.413,41.045-41.045V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23
		c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727
		c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23l-55.053,44.045L177.457,348.77z M304.62,214.345
		l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543
		c2.977-6.945,11.02-10.162,17.965-7.186C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727
		c-5.891,4.714-14.503,3.772-19.23-2.136c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043
		c-5.9-4.72-6.857-13.329-2.137-19.23c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727
		C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2" display="none">
        <g display="inline">
          <g>
            <path
              d="M165.291,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
				c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
				C185.471,235.854,175.684,238.497,165.291,238.497z"
            />
            <path
              d="M165.291,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
				c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
				C185.471,305.35,175.684,307.992,165.291,307.992z"
            />
            <path
              d="M268.621,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
				c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
				L78.663,18H61.96c-26.472,0-48.013,21.542-48.013,48.013v367.973C13.947,460.459,35.489,482,61.96,482h206.661
				c26.473,0,48.014-21.541,48.014-48.014V66.013C316.635,39.542,295.094,18,268.621,18z M194.012,444.518h-57.441
				c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
				S201.518,444.518,194.012,444.518z M225.6,247.683c0,12.923-4.086,24.908-11.033,34.735c7.108,10.089,11.033,22.227,11.033,34.76
				c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308c0-12.533,3.923-24.671,11.033-34.76
				c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76c-6.947-9.826-11.033-21.812-11.033-34.735
				c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308c0,12.923-4.086,24.909-11.033,34.735
				C221.675,223.013,225.6,235.151,225.6,247.683z"
            />
            <cir cx="165.291" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3" display="none">
        <g display="inline">
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
			V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
			c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
			l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
			C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
			H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
			c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
      <g id="Layer_4" display="none">
        <path
          display="inline"
          d="M290.894,133.084V44.288c0-17.69-14.461-32.118-32.118-32.118H46.688
		c-17.689,0-32.118,14.428-32.118,32.118v411.435c0,17.689,14.429,32.107,32.118,32.107h212.077
		c17.668,0,32.107-14.418,32.107-32.107V363.39h-22.333v65.017H36.894V63.107h231.646v69.978H290.894z M152.71,439.637
		c8.887,0,16.096,7.199,16.096,16.053c-0.021,8.888-7.209,16.096-16.096,16.096c-8.866,0-16.064-7.208-16.064-16.096
		C136.646,446.836,143.844,439.637,152.71,439.637z M186.633,43.095h-67.846c-2.143,0-3.874-1.763-3.874-3.884
		c0-2.153,1.731-3.853,3.874-3.853h67.846c2.143,0,3.884,1.699,3.884,3.853C190.517,41.332,188.776,43.095,186.633,43.095z"
        />
        <g display="inline">
          <path
            d="M298.293,286.764c0,3.658-2.979,6.631-6.629,6.631H171.92c-3.665,0-6.629,2.965-6.629,6.625
			c0,3.666,2.964,6.627,6.629,6.627h119.744c10.964,0,19.884-8.918,19.884-19.883c0-8.156-4.945-15.166-11.986-18.227
			l23.934-107.702h34.89c3.662,0,6.627-2.963,6.627-6.628c0-3.663-2.965-6.628-6.627-6.628h-40.209
			c-3.107,0-5.801,2.156-6.474,5.192l-4.737,21.319H145.408c-2.084,0-4.039,0.979-5.295,2.635c-1.254,1.663-1.645,3.811-1.076,5.812
			l26.513,92.792c0.804,2.846,3.404,4.807,6.37,4.807h119.744C295.314,280.137,298.293,283.107,298.293,286.764z"
          />
          <path
            d="M278.406,306.646c-10.963,0-19.883,8.924-19.883,19.887c0,10.965,8.92,19.883,19.883,19.883
			c10.966,0,19.887-8.918,19.887-19.883C298.293,315.57,289.372,306.646,278.406,306.646z"
          />
          <path
            d="M185.176,306.646c-10.966,0-19.885,8.924-19.885,19.887c0,10.965,8.919,19.883,19.885,19.883
			c10.963,0,19.883-8.918,19.883-19.883C205.059,315.57,196.139,306.646,185.176,306.646z"
          />
        </g>
      </g>
      <g id="Layer_5">
        <g>
          <path
            d="M288.551,363.391v65.02H56.901V363h-22.32v92.721c0,17.689,14.43,32.109,32.12,32.109h212.07
			c17.67,0,32.109-14.42,32.109-32.109v-92.33H288.551z M172.721,471.791c-8.87,0-16.06-7.211-16.06-16.1
			c0-8.852,7.19-16.051,16.06-16.051c8.89,0,16.101,7.199,16.101,16.051C188.801,464.58,181.611,471.791,172.721,471.791z"
          />
          <g id="Cloud_service">
            <cir cx="172.695" cy="246.08" r="17.17" />
            <path
              d="M332.955,183.121c-0.037-23.643-14.584-44.836-36.631-53.372c-2.471-0.939-3.979-3.447-3.646-6.067
				c2.041-15.578-8.936-29.859-24.514-31.897c-1.289-0.17-2.59-0.251-3.891-0.241c-3.992-0.032-7.943,0.804-11.58,2.455
				c-2.873,1.319-6.271,0.059-7.588-2.814c-0.146-0.312-0.26-0.636-0.346-0.968c-10.227-39.8-50.782-63.776-90.583-53.547
				c-26.277,6.751-46.795,27.269-53.548,53.547c-0.783,3.061-3.901,4.91-6.963,4.125c-0.333-0.084-0.658-0.2-0.969-0.343
				c-3.636-1.651-7.587-2.487-11.579-2.455c-15.71-0.095-28.522,12.563-28.617,28.273c-0.009,1.293,0.072,2.584,0.24,3.865
				c0.331,2.62-1.176,5.127-3.645,6.067c-29.479,11.41-44.126,44.558-32.713,74.037c7.771,20.075,26.149,34.094,47.565,36.278
				V228.91c0-3.162,2.563-5.725,5.724-5.725h20.25c1.876-6.794,4.589-13.332,8.075-19.459l-14.343-14.309
				c-2.234-2.234-2.234-5.858,0-8.093l24.268-24.268c2.27-2.147,5.823-2.147,8.093,0l14.326,14.326
				c6.127-3.487,12.664-6.2,19.46-8.077v-20.249c0-3.162,2.563-5.723,5.723-5.723h34.342c3.161,0,5.723,2.562,5.723,5.723v20.249
				c6.796,1.879,13.334,4.59,19.46,8.077l14.311-14.344c2.235-2.234,5.858-2.234,8.094,0l24.266,24.269
				c2.234,2.234,2.234,5.856,0,8.093l-14.326,14.325c3.486,6.127,6.199,12.665,8.076,19.459h20.25c3.16,0,5.725,2.563,5.725,5.725
				v11.116C310.76,237.264,333.117,212.568,332.955,183.121z"
            />
            <path
              d="M172.695,200.292c-25.288,0-45.789,20.501-45.789,45.789c0,25.287,20.5,45.788,45.789,45.788
				c25.289,0,45.788-20.501,45.788-45.788C218.455,220.803,197.972,200.32,172.695,200.292z M172.695,274.697
				c-15.806,0-28.618-12.811-28.618-28.617s12.812-28.619,28.618-28.619s28.618,12.813,28.618,28.619
				C201.294,261.876,188.493,274.678,172.695,274.697z"
            />
            <path
              d="M269.994,234.633h-19.002c-2.689,0-5.016-1.873-5.592-4.499c-1.881-8.59-5.283-16.776-10.045-24.17
				c-1.455-2.266-1.131-5.24,0.773-7.144l13.469-13.467l-16.176-16.175l-13.467,13.468c-1.903,1.901-4.874,2.22-7.138,0.766
				c-7.403-4.747-15.591-8.142-24.182-10.022c-2.631-0.578-4.503-2.914-4.493-5.608V148.78h-22.894v19.002
				c-0.002,2.685-1.87,5.008-4.493,5.586c-8.593,1.889-16.781,5.289-24.182,10.044c-2.264,1.455-5.235,1.136-7.137-0.766
				l-13.468-13.468l-16.174,16.175l13.467,13.467c1.906,1.903,2.228,4.877,0.772,7.144c-4.755,7.396-8.15,15.58-10.027,24.17
				c-0.577,2.632-2.913,4.506-5.609,4.499H75.395v22.893h19.002c2.689,0,5.017,1.874,5.592,4.5
				c1.882,8.59,5.283,16.775,10.044,24.17c1.455,2.267,1.134,5.24-0.772,7.143l-13.467,13.467l16.174,16.178l13.468-13.469
				c1.902-1.901,4.874-2.221,7.137-0.768c7.403,4.748,15.59,8.143,24.182,10.021c2.631,0.58,4.502,2.916,4.493,5.609v19.002h22.894
				v-19.002c0.002-2.686,1.87-5.008,4.493-5.586c8.592-1.889,16.78-5.289,24.182-10.045c2.264-1.453,5.234-1.134,7.138,0.768
				l13.467,13.469l16.176-16.178l-13.469-13.467c-1.904-1.902-2.229-4.876-0.773-7.143c4.756-7.396,8.15-15.58,10.029-24.17
				c0.576-2.633,2.912-4.508,5.607-4.5h19.002V234.633z M172.695,303.314c-31.61,0-57.235-25.625-57.235-57.234
				c0-31.611,25.625-57.235,57.235-57.235s57.235,25.625,57.235,57.235C229.895,277.676,204.29,303.28,172.695,303.314z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const DesktopIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="Layer_1" display="none">
        <path
          display="inline"
          d="M484.5,126.865V58.457c0-22.632-18.413-41.045-41.045-41.045H58.545
		c-22.632,0-41.045,18.413-41.045,41.045v68.408H484.5z M333.09,72.139h82.09c7.556,0,13.682,6.125,13.682,13.682
		s-6.126,13.682-13.682,13.682h-82.09c-7.556,0-13.682-6.125-13.682-13.682S325.534,72.139,333.09,72.139z M196.273,72.139
		c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682S188.717,72.139,196.273,72.139z
		 M141.547,72.139c7.556,0,13.682,6.125,13.682,13.682s-6.125,13.682-13.682,13.682s-13.682-6.125-13.682-13.682
		S133.991,72.139,141.547,72.139z M86.82,72.139c7.556,0,13.682,6.125,13.682,13.682S94.376,99.502,86.82,99.502
		S73.139,93.376,73.139,85.82S79.264,72.139,86.82,72.139z"
        />
        <path
          display="inline"
          d="M17.5,154.229v287.314c0,22.632,18.413,41.045,41.045,41.045h384.91
		c22.632,0,41.045-18.413,41.045-41.045V154.229H17.5z M177.457,348.77c5.901,4.72,6.857,13.33,2.137,19.23
		c-4.719,5.899-13.331,6.856-19.23,2.136l-68.408-54.727c-6.84-5.47-6.845-15.893,0-21.367l68.408-54.727
		c5.897-4.72,14.509-3.764,19.23,2.136c4.72,5.901,3.764,14.512-2.137,19.23l-55.053,44.045L177.457,348.77z M304.62,214.345
		l-82.09,191.543c-2.976,6.943-11.017,10.163-17.965,7.186c-6.945-2.976-10.163-11.02-7.186-17.965l82.09-191.543
		c2.977-6.945,11.02-10.162,17.965-7.186C304.38,199.356,307.597,207.399,304.62,214.345z M410.045,315.41l-68.408,54.727
		c-5.891,4.714-14.503,3.772-19.23-2.136c-4.72-5.9-3.764-14.512,2.137-19.23l55.054-44.044l-55.054-44.043
		c-5.9-4.72-6.857-13.329-2.137-19.23c4.719-5.9,13.329-6.857,19.23-2.136l68.408,54.727
		C416.885,299.514,416.889,309.936,410.045,315.41L410.045,315.41z"
        />
      </g>
      <g id="Layer_2" display="none">
        <g display="inline">
          <g>
            <path
              d="M165.291,238.497c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.477
				c0,18.263,14.857,33.121,33.121,33.121c18.263,0,33.121-14.858,33.121-33.121c0-5.832-1.549-11.507-4.396-16.477
				C185.471,235.854,175.684,238.497,165.291,238.497z"
            />
            <path
              d="M165.291,307.992c-10.393,0-20.18-2.643-28.725-7.291c-2.848,4.969-4.396,10.646-4.396,16.478
				c0,18.263,14.857,33.12,33.121,33.12c18.263,0,33.121-14.857,33.121-33.12c0-5.832-1.549-11.508-4.396-16.478
				C185.471,305.35,175.684,307.992,165.291,307.992z"
            />
            <path
              d="M268.621,18h-16.193l-11.755,31.873c-0.073,0.19-0.146,0.372-0.218,0.562
				c-6.208,14.781-20.58,24.333-36.612,24.333h-77.104c-16.032,0-30.405-9.552-36.613-24.333c-0.099-0.245-0.19-0.498-0.281-0.743
				L78.663,18H61.96c-26.472,0-48.013,21.542-48.013,48.013v367.973C13.947,460.459,35.489,482,61.96,482h206.661
				c26.473,0,48.014-21.541,48.014-48.014V66.013C316.635,39.542,295.094,18,268.621,18z M194.012,444.518h-57.441
				c-7.507,0-13.594-6.087-13.594-13.594s6.087-13.594,13.594-13.594h57.441c7.506,0,13.594,6.087,13.594,13.594
				S201.518,444.518,194.012,444.518z M225.6,247.683c0,12.923-4.086,24.908-11.033,34.735c7.108,10.089,11.033,22.227,11.033,34.76
				c0,33.254-27.056,60.308-60.309,60.308c-33.254,0-60.308-27.054-60.308-60.308c0-12.533,3.923-24.671,11.033-34.76
				c-6.947-9.827-11.033-21.813-11.033-34.735c0-12.532,3.924-24.671,11.033-34.76c-6.947-9.826-11.033-21.812-11.033-34.735
				c0-33.253,27.054-60.308,60.308-60.308c33.253,0,60.309,27.055,60.309,60.308c0,12.923-4.086,24.909-11.033,34.735
				C221.675,223.013,225.6,235.151,225.6,247.683z"
            />
            <cir cx="165.291" cy="178.189" r="33.121" />
          </g>
        </g>
      </g>
      <g id="Layer_3" display="none">
        <g display="inline">
          <path
            d="M15.659,446.768c0,18.932,14.888,34.277,33.254,34.277h399.04c18.366,0,33.255-15.346,33.255-34.277
			V412.49H15.659V446.768z M414.698,435.342h22.171v22.852h-22.171V435.342z"
          />
          <path d="M342.865,213.938l31.66-6.534L497.323,80.845l-25.338-26.117L349.204,181.307L342.865,213.938z" />
          <ellipse cx="105.188" cy="218.996" rx="17.906" ry="18.457" />
          <path
            d="M51.471,385.108h401.759c14.922,0,27.979-17.365,27.979-30.377V149.653l-85.197,87.8
			c-2.496,2.579-5.682,4.333-9.149,5.037l-63.226,13.049c-9.687,2.042-19.146-4.399-21.128-14.385c-0.484-2.438-0.484-4.953,0-7.392
			l12.661-65.246c0.698-3.548,2.397-6.805,4.887-9.357L423.283,52.734L51.471,52.882c-24.248,0-35.812,8.077-35.812,33.456v266.858
			C15.659,380.112,30.208,385.108,51.471,385.108z M140.999,348.195H69.376v-36.916h71.623V348.195z M69.376,89.797h71.623v36.914
			H69.376V89.797z M105.188,163.625c29.668,0,53.717,24.791,53.717,55.372c0,30.579-24.049,55.37-53.717,55.37
			c-29.667,0-53.717-24.791-53.717-55.37C51.471,188.416,75.52,163.625,105.188,163.625z"
          />
          <path d="M492.245,33.547l25.317-26.099l25.333,26.112l-25.321,26.098L492.245,33.547z" />
        </g>
      </g>
      <g id="Layer_4" display="none">
        <path
          display="inline"
          d="M290.894,133.084V44.288c0-17.69-14.461-32.118-32.118-32.118H46.688
		c-17.689,0-32.118,14.428-32.118,32.118v411.435c0,17.689,14.429,32.107,32.118,32.107h212.077
		c17.668,0,32.107-14.418,32.107-32.107V363.39h-22.333v65.017H36.894V63.107h231.646v69.978H290.894z M152.71,439.637
		c8.887,0,16.096,7.199,16.096,16.053c-0.021,8.888-7.209,16.096-16.096,16.096c-8.866,0-16.064-7.208-16.064-16.096
		C136.646,446.836,143.844,439.637,152.71,439.637z M186.633,43.095h-67.846c-2.143,0-3.874-1.763-3.874-3.884
		c0-2.153,1.731-3.853,3.874-3.853h67.846c2.143,0,3.884,1.699,3.884,3.853C190.517,41.332,188.776,43.095,186.633,43.095z"
        />
        <g display="inline">
          <path
            d="M298.293,286.764c0,3.658-2.979,6.631-6.629,6.631H171.92c-3.665,0-6.629,2.965-6.629,6.625
			c0,3.666,2.964,6.627,6.629,6.627h119.744c10.964,0,19.884-8.918,19.884-19.883c0-8.156-4.945-15.166-11.986-18.227
			l23.934-107.702h34.89c3.662,0,6.627-2.963,6.627-6.628c0-3.663-2.965-6.628-6.627-6.628h-40.209
			c-3.107,0-5.801,2.156-6.474,5.192l-4.737,21.319H145.408c-2.084,0-4.039,0.979-5.295,2.635c-1.254,1.663-1.645,3.811-1.076,5.812
			l26.513,92.792c0.804,2.846,3.404,4.807,6.37,4.807h119.744C295.314,280.137,298.293,283.107,298.293,286.764z"
          />
          <path
            d="M278.406,306.646c-10.963,0-19.883,8.924-19.883,19.887c0,10.965,8.92,19.883,19.883,19.883
			c10.966,0,19.887-8.918,19.887-19.883C298.293,315.57,289.372,306.646,278.406,306.646z"
          />
          <path
            d="M185.176,306.646c-10.966,0-19.885,8.924-19.885,19.887c0,10.965,8.919,19.883,19.885,19.883
			c10.963,0,19.883-8.918,19.883-19.883C205.059,315.57,196.139,306.646,185.176,306.646z"
          />
        </g>
      </g>
      <g id="Layer_5" display="none">
        <g display="inline">
          <path
            d="M288.551,363.391v65.02H56.901V363h-22.32v92.721c0,17.689,14.43,32.109,32.12,32.109h212.07
			c17.67,0,32.109-14.42,32.109-32.109v-92.33H288.551z M172.721,471.791c-8.87,0-16.06-7.211-16.06-16.1
			c0-8.852,7.19-16.051,16.06-16.051c8.89,0,16.101,7.199,16.101,16.051C188.801,464.58,181.611,471.791,172.721,471.791z"
          />
          <g id="Cloud_service">
            <cir cx="172.695" cy="246.08" r="17.17" />
            <path
              d="M332.955,183.121c-0.037-23.643-14.584-44.836-36.631-53.372c-2.471-0.939-3.979-3.447-3.646-6.067
				c2.041-15.578-8.936-29.859-24.514-31.897c-1.289-0.17-2.59-0.251-3.891-0.241c-3.992-0.032-7.943,0.804-11.58,2.455
				c-2.873,1.319-6.271,0.059-7.588-2.814c-0.146-0.312-0.26-0.636-0.346-0.968c-10.227-39.8-50.782-63.776-90.583-53.547
				c-26.277,6.751-46.795,27.269-53.548,53.547c-0.783,3.061-3.901,4.91-6.963,4.125c-0.333-0.084-0.658-0.2-0.969-0.343
				c-3.636-1.651-7.587-2.487-11.579-2.455c-15.71-0.095-28.522,12.563-28.617,28.273c-0.009,1.293,0.072,2.584,0.24,3.865
				c0.331,2.62-1.176,5.127-3.645,6.067c-29.479,11.41-44.126,44.558-32.713,74.037c7.771,20.075,26.149,34.094,47.565,36.278
				V228.91c0-3.162,2.563-5.725,5.724-5.725h20.25c1.876-6.794,4.589-13.332,8.075-19.459l-14.343-14.309
				c-2.234-2.234-2.234-5.858,0-8.093l24.268-24.268c2.27-2.147,5.823-2.147,8.093,0l14.326,14.326
				c6.127-3.487,12.664-6.2,19.46-8.077v-20.249c0-3.162,2.563-5.723,5.723-5.723h34.342c3.161,0,5.723,2.562,5.723,5.723v20.249
				c6.796,1.879,13.334,4.59,19.46,8.077l14.311-14.344c2.235-2.234,5.858-2.234,8.094,0l24.266,24.269
				c2.234,2.234,2.234,5.856,0,8.093l-14.326,14.325c3.486,6.127,6.199,12.665,8.076,19.459h20.25c3.16,0,5.725,2.563,5.725,5.725
				v11.116C310.76,237.264,333.117,212.568,332.955,183.121z"
            />
            <path
              d="M172.695,200.292c-25.288,0-45.789,20.501-45.789,45.789c0,25.287,20.5,45.788,45.789,45.788
				c25.289,0,45.788-20.501,45.788-45.788C218.455,220.803,197.972,200.32,172.695,200.292z M172.695,274.697
				c-15.806,0-28.618-12.811-28.618-28.617s12.812-28.619,28.618-28.619s28.618,12.813,28.618,28.619
				C201.294,261.876,188.493,274.678,172.695,274.697z"
            />
            <path
              d="M269.994,234.633h-19.002c-2.689,0-5.016-1.873-5.592-4.499c-1.881-8.59-5.283-16.776-10.045-24.17
				c-1.455-2.266-1.131-5.24,0.773-7.144l13.469-13.467l-16.176-16.175l-13.467,13.468c-1.903,1.901-4.874,2.22-7.138,0.766
				c-7.403-4.747-15.591-8.142-24.182-10.022c-2.631-0.578-4.503-2.914-4.493-5.608V148.78h-22.894v19.002
				c-0.002,2.685-1.87,5.008-4.493,5.586c-8.593,1.889-16.781,5.289-24.182,10.044c-2.264,1.455-5.235,1.136-7.137-0.766
				l-13.468-13.468l-16.174,16.175l13.467,13.467c1.906,1.903,2.228,4.877,0.772,7.144c-4.755,7.396-8.15,15.58-10.027,24.17
				c-0.577,2.632-2.913,4.506-5.609,4.499H75.395v22.893h19.002c2.689,0,5.017,1.874,5.592,4.5
				c1.882,8.59,5.283,16.775,10.044,24.17c1.455,2.267,1.134,5.24-0.772,7.143l-13.467,13.467l16.174,16.178l13.468-13.469
				c1.902-1.901,4.874-2.221,7.137-0.768c7.403,4.748,15.59,8.143,24.182,10.021c2.631,0.58,4.502,2.916,4.493,5.609v19.002h22.894
				v-19.002c0.002-2.686,1.87-5.008,4.493-5.586c8.592-1.889,16.78-5.289,24.182-10.045c2.264-1.453,5.234-1.134,7.138,0.768
				l13.467,13.469l16.176-16.178l-13.469-13.467c-1.904-1.902-2.229-4.876-0.773-7.143c4.756-7.396,8.15-15.58,10.029-24.17
				c0.576-2.633,2.912-4.508,5.607-4.5h19.002V234.633z M172.695,303.314c-31.61,0-57.235-25.625-57.235-57.234
				c0-31.611,25.625-57.235,57.235-57.235s57.235,25.625,57.235,57.235C229.895,277.676,204.29,303.28,172.695,303.314z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_6">
        <g>
          <path
            d="M440.339,99.061h-62.337c-10.587,0-19.179,8.666-19.179,19.343c0,10.673,8.592,19.339,19.179,19.339
			h57.541v212.744H51.942V137.743h57.541c10.585,0,19.179-8.666,19.179-19.339c0-10.677-8.594-19.343-19.179-19.343H47.145
			c-18.526,0-33.564,15.164-33.564,33.847v261.095c0,18.681,15.038,33.846,33.564,33.846h146.881
			c-2.261,9.688-8.228,25.412-24.205,41.503c-2.742,2.767-3.568,6.923-2.072,10.539c1.479,3.618,4.987,5.979,8.861,5.979h134.262
			c3.875,0,7.383-2.36,8.861-5.979c1.475-3.616,0.649-7.772-2.072-10.539c-15.937-16.069-22.076-31.793-24.452-41.503h147.129
			c18.526,0,33.564-15.165,33.564-33.846V132.908C473.903,114.225,458.865,99.061,440.339,99.061z"
          />
          <path
            d="M146.496,171.002l16.919,29.314c2.638,4.585,8.27,6.468,13.151,4.417l21.712-9.171
			c5.298,4.041,10.931,7.603,17.149,10.198l2.887,23.054c0.652,5.257,5.091,9.19,10.367,9.19h33.858
			c5.275,0,9.735-3.934,10.387-9.171l2.887-23.054c6.219-2.596,11.874-6.177,17.149-10.195l21.712,9.148
			c4.881,2.051,10.513,0.168,13.151-4.417l16.918-29.314c2.639-4.566,1.466-10.386-2.74-13.587l-18.868-14.282
			c0.418-3.244,1.006-6.449,1.006-9.819c0-3.374-0.588-6.575-0.985-9.823l18.866-14.278c4.21-3.182,5.383-9.006,2.741-13.591
			l-16.938-29.315c-2.639-4.562-8.271-6.468-13.129-4.417l-21.712,9.151c-5.298-4.041-10.93-7.603-17.148-10.198l-2.892-23.054
			c-0.67-5.234-5.11-9.171-10.386-9.171H228.7c-5.275,0-9.734,3.937-10.386,9.171l-2.887,23.054
			c-6.242,2.595-11.874,6.176-17.173,10.198l-21.688-9.151c-4.881-2.051-10.513-0.168-13.151,4.417l-16.919,29.315
			c-2.638,4.566-1.464,10.386,2.742,13.591l18.867,14.278c-0.418,3.248-1.004,6.449-1.004,9.823c0,3.37,0.586,6.575,0.985,9.819
			l-18.848,14.282C145.032,160.597,143.835,166.417,146.496,171.002z M245.62,91.436c23.138,0,41.879,18.74,41.879,41.878
			c0,23.134-18.741,41.875-41.879,41.875s-41.878-18.74-41.878-41.875C203.742,110.176,222.482,91.436,245.62,91.436z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Service;
