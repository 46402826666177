// React
import React from "react";

// React bootstrap
import { Row, Col } from "react-bootstrap";

// Local Imports
import { PageTitle, ResponsiveBox, Tool, Cta } from "../../../components";

// styles
import styles from "../scss/title.module.scss";

const Title = () => {
  return (
    <>
      <div className={styles.outerCover}>
        <ResponsiveBox>
          <Row>
            <Col xs={12} lg={5}>
              <div className={styles.cover}>
                  <div className={styles.content}>
                      <h2 className={styles.title}>Enlead</h2>
                      <h6 className={styles.backgroundTxt}>Ed-tech Startup</h6>
                      <p className={styles.subtitle}>Online learning platform that connects native-speaking teachers to ESL learners globally for a better learning experience.</p>
                  </div>
              </div>
            </Col>
            <Col xs={12} lg={7} className={styles.col2}>
              <div className={styles.cover}>
                  <div className={styles.imageCover}>
                  <img src="https://otakoyi.com/_cases/enlead/an.gif" className={styles.image} alt="project-gif" />
                  </div>
              </div>
            </Col>
          </Row>
        </ResponsiveBox>
      </div>
    </>
  );
};

export default Title;
