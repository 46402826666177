// React
import React from 'react';

// React router dom
import {useHistory} from 'react-router-dom'


// styles
import styles from "./cta.module.scss";

const Cta = () => {
    const history = useHistory();
    return ( 
        <div className={styles.cover}>
            <div className={styles.divider}></div>
            <div className={styles.content}>
                <h1>Ready to get started?</h1>
                <p>We are ready to offer the most optimal solution for you to achieve your business or project goals</p>
                <button className={styles.button}  onClick={() => history.push('/contact')}>Let's Talk </button>
            </div>
            <div className={styles.divider}></div>
        </div>
     );
}
 
export default Cta;