// React
import React from "react";

// React-bootstrap
import { Row, Col } from "react-bootstrap";

// Local Imports
import { ResponsiveBox } from "../../../components";

// styles
import styles from "../scss/value.module.scss";

const Value = () => {
  return (
    <React.Fragment>
      <div className={styles.cover}>
        <div className={styles.divider}></div>
        <ResponsiveBox>
          <Row>
            <Col xs={12} lg={6}>
              <div className={styles.cover1}>
                <div className={styles.box}>
                  <div className={styles.divider}></div>
                  <h2>Our Business Values</h2>
                  <p>
                    We want to promote us not by ourselves but from our clients
                    which is only possible when we provide you the solution that
                    your business is demanding in the current time.
                  </p>
                  <div className={styles.divider}></div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className={styles.cover2}>
                <div className={styles.content}>
                  <div className={styles.flexContainer}>
                    <OneIcon className={styles.icon} />
                    <p>
                      We go through deep research and analysis of your business
                      to identify the real problems and take it as an
                      opportunity for its growth and success.
                    </p>
                  </div>
                  <div className={styles.flexContainer}>
                    <TwoIcon className={styles.icon} />
                    <p>
                      The most significant part of web, the UI will compel your
                      clients to stay longer n visit freqeuntly reflecting your
                      business value.
                    </p>
                  </div>
                  <div className={styles.flexContainer}>
                    <ThreeIcon className={styles.icon} />
                    <p>
                      Repetative iteration to make the product perfect for which
                      we will be dedicated to. We improve your business until
                      you are fully satisfied.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </ResponsiveBox>
        <div className={styles.divider}></div>
      </div>
    </React.Fragment>
  );
};

const OneIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <circle
        fill="none"
        stroke="#000000"
        strokeWidth="20"
        strokeMiterlimit="10"
        cx="250"
        cy="249.999"
        r="244"
      />
      <text
        transform="matrix(1 0 0 1 177.5513 332.9023)"
        fontFamily="'MyriadPro-Regular'"
        fontSize="282.4598"
      >
        1
      </text>
    </svg>
  );
};

const TwoIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <circle
        fill="none"
        stroke="#000000"
        strokeWidth="20"
        strokeMiterlimit="10"
        cx="250"
        cy="249.999"
        r="244"
      />
      <text
        transform="matrix(1 0 0 1 177.5513 332.9023)"
        fontFamily="'MyriadPro-Regular'"
        fontSize="282.4598"
      >
        2
      </text>
    </svg>
  );
};

const ThreeIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="500px"
      height="500px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <circle
        fill="none"
        stroke="#000000"
        strokeWidth="20"
        strokeMiterlimit="10"
        cx="250"
        cy="249.999"
        r="244"
      />
      <text
        transform="matrix(1 0 0 1 177.5513 332.9023)"
        fontFamily="'MyriadPro-Regular'"
        fontSize="282.4598"
      >
        3
      </text>
    </svg>
  );
};

export default Value;
