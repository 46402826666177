import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {Home, About, Service, Tech, Contact, Project, Blogs, ProjectDetail, PageNotFound} from "../pages";


class RouterRoute extends Component {
    render() {
      const baseUrl = process.env.PUBLIC_URL;
  
      return (
        <Switch>
          <Route exact path={baseUrl + "/"} component={Home} />
          <Route exact path={baseUrl + "/services"} component={Service} />
          <Route exact path={baseUrl + "/about"} component={About} />
          <Route exact path={baseUrl + "/technologies"} component={Tech} />
          <Route exact path={baseUrl + "/projects"} component={Project} />
          <Route exact path={baseUrl + "/blogs"} component={Blogs} />
          <Route exact path={baseUrl + "/project/:id"} component={ProjectDetail} />
          <Route exact path={baseUrl + "/contact"} component={Contact} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      );
    }
  }
  
  export default RouterRoute;
  
