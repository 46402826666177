// React
import React from "react";

// React Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

// Local Import
import { ResponsiveBox } from "../../components";
import logo from "../../assets/images/logoForFooter.png";

// Styles
import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <footer>
      <div className={styles.cover}>
        <div className={styles.dividerHalf}></div>
        <ResponsiveBox>
          <img src={logo} className={styles.logo} alt="footer-logo" />

          <div className={styles.socialFlexContainer}>
            <a
              href="https://www.facebook.com/firstpaddle/"
              target="_blank"
              style={{ textDecoration: "none", outline: 'none' }}
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className={styles.socialIcon}
              />
            </a>
            <a
              href="https://www.instagram.com/firstpaddle/"
              target="_blank"
              style={{ textDecoration: "none", outline: 'none' }}
            >
            <FontAwesomeIcon
              icon={faInstagramSquare}
              className={styles.socialIcon}
            />
            </a>
            <a
              href="https://www.linkedin.com/company/firstpaddle/"
              target="_blank"
              style={{ textDecoration: "none", outline: 'none' }}
            >
            <FontAwesomeIcon icon={faLinkedin} className={styles.socialIcon} /></a>
            <a
              href="https://twitter.com/firstpaddle2019"
              target="_blank"
              style={{ textDecoration: "none", outline: 'none' }}
            >
            <FontAwesomeIcon
              icon={faTwitterSquare}
              className={styles.socialIcon}
            />
            </a>
          </div>

          <p className={styles.copyright}>&copy;2020 Firstpaddle Pvt Ltd.</p>
        </ResponsiveBox>
        <div className={styles.smallDivider}></div>
      </div>
    </footer>
  );
};

export default Footer;
