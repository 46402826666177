// React
import React from "react";

// Local Imports
import ResponsiveBox from "../box/responsiveBox";

// styles
import styles from "./pageTitle.module.scss";

const PageTitle = (props) => {
  return (
    <React.Fragment>
      <div className={styles.cover}>
        <ResponsiveBox className={styles.content}>
          <h1>{props.title}</h1>
          <p>{props.subtitle}</p>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default PageTitle;
