// React
import React from "react";

// React router dom
import {useHistory} from 'react-router-dom'

// Local Imports
import { ResponsiveBox } from "../../../components";

// styles
import "../../../scss/shared.scss";
import styles from "../scss/landingImage.module.scss";

const LandingImage = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className={styles.bg}>
        <ResponsiveBox className={styles.cover}>
          <div className={styles.content}>
            <h1>
              We transform ideas into <br />
              innovative services
            </h1>
            <p>
              A perfect digital partner for your business offering
              cost-efficient solutions for creative design, effective web,
              practical mobile application
            </p>
            <br />
            <button className={styles.button} onClick={() => history.push('/services')}>Discover </button>
          </div>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default LandingImage;
