// React
import React from "react";

// React-bootstrap
import { Row, Col } from "react-bootstrap";

// Local Imports
import { ResponsiveBox } from "../../../components";

// styles
import styles from "../scss/story.module.scss";

const Story = () => {
  return (
    <React.Fragment>
      <ResponsiveBox>
        <Row>
          <Col xs={12} lg={6}>
            <div className={styles.cover1}>
              <h4>The story of FIRST PADDLE</h4>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className={styles.cover2}>
              <p>
                Firspaddle is an IT company which is focused in well-designed UI
                interactive web development. It was founded by BE students
                in Electronics and Communication from Pulchowk Campus, Lalitpur
                in 2019. Our team consists of highly motivated, focused, skilled
                and enthusiastic young developers. With the creative young minds
                of our co-founders specialized in different corresponding
                fields, we actively promote creative and critical thinking and
                help support new business opportunities in this ever growing
                competitive market. The core skill of our team is directed in
                creating interactive, responsive and business oriented web
                applications. Our team holds the required skills of delivering
                the services that would not only possess a high-quality
                representation but would also look impressive and fascinating.
              </p>
            </div>
          </Col>
        </Row>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default Story;
