// React
import React from 'react';

// React Loading
import ReactLoading from 'react-loading';

// Styles
import styles from './loader.module.scss';

const Loader = () => {
  return (
    <div className={styles.cover}>
      <ReactLoading type='bubbles' color='#feb300' height='80px' width='80px' />
    </div>
  );
};

export default Loader;
