// React
import React from 'react';

// React Bootstrap
import { Row, Col } from 'react-bootstrap';

// Local Imports
import { ResponsiveBox } from '../../../components';
import leftArrow from '../../../assets/images/leftArrow.svg';
import { dateFormatter } from '../../../utils';

// Styles
import styles from '../scss/blogDetail.module.scss';

const BlogDetail = ({ showList, blog, relatedBlogs, selectBlog }) => {
  return (
    <ResponsiveBox style={{ overflow: 'hidden' }}>
      <div className={styles.redirectCover} onClick={showList}>
        <img className={styles.leftArrow} src={leftArrow} alt='left-arrow' />
        <p className={styles.redirectText}>Back</p>
      </div>

      <h1 className={styles.title}>{blog.title}</h1>

      <div className={styles.authorCover}>
        <img
          className={styles.author}
          src={blog.metadata && blog.metadata.author_image.url}
          alt='author-image'
        />
        <div>
          <p className={styles.authorName}>
            {blog.metadata && blog.metadata.author}
          </p>
          <p className={styles.date}>
            {' '}
            {dateFormatter(blog.metadata && blog.metadata.published_date)}{' '}
          </p>
        </div>
      </div>

      <Row className={styles.blogBodySection}>
        <Col xs={12} xl={8} className={styles.blogBody}>
          <div className={styles.imageCover}>
            <img
              className={styles.image}
              src={blog.metadata && blog.metadata.image.url}
              alt='blog-image'
            />
          </div>

          <div
            className={styles.blogContent}
            dangerouslySetInnerHTML={{ __html: blog.content }}
          />
        </Col>
        <Col xs={12} xl={4} className={styles.relatedBlogs}>
          <h6 className={styles.relatedBlogsHeading}>You may also like</h6>
          <Row>
            {relatedBlogs &&
              relatedBlogs.map((item, index) => (
                <Col
                  xs={12}
                  className={styles.gridCol}
                  key={index}
                  onClick={() => selectBlog(item.slug)}
                >
                  <div className={styles.blog}>
                    <div className={styles.imageCover}>
                      <img
                        className={styles.image}
                        src={item.metadata && item.metadata.image.url}
                        alt='blog-image'
                      />
                    </div>
                    <div className={styles.contextCover}>
                      <p className={styles.author}>
                        {item.metadata && item.metadata.author},{' '}
                      </p>
                      <p className={styles.date}>
                        {dateFormatter(
                          item.metadata && item.metadata.published_date
                        )}
                      </p>
                    </div>
                    <h6 className={styles.title}>{item.title}</h6>
                  </div>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </ResponsiveBox>
  );
};

export default BlogDetail;
