// React
import React from "react";

// Local Imports
import { Header, Footer } from "../../layouts";
import {Cta} from "../../components"
import {Title, Background, Content} from "./js";

// styles
import styles from "./projectDetail.module.scss";

const ProjectDetail = () => {
  return (
    <>
      <Header />
      <Title />
      <Background />
      <Content />
      <Cta />
      <Footer />
    </>
  );
};

export default ProjectDetail;
