// React
import React from "react";

// React bootstrap
import { Row, Col } from "react-bootstrap";

// Local Imports
import { PageTitle, ResponsiveBox, Tool, Cta } from "../../../components";

// styles
import styles from "../scss/background.module.scss";

const Background = () => {
  return (
    <>
      <div className={styles.outerCover}>
        <ResponsiveBox className={styles.box}>
            <div className={styles.dividerHalf}></div>
          <h6 className={styles.background}>
            Background
          </h6>
          <h4 className={styles.content}>The fast-changing world dictates its rules and demands the implementation of new solutions that will make our living standards higher. It relates to the sphere of education in particular. Being an experienced teacher of English, our client launched an ed-tech start-up to help the ESL learners keep pace with this rhythm and achieve high academic results. Thus, Enlead is an online learning platform that represents a fully functional and easily accessible service for those who want to learn and practice English with native-speaking teachers.</h4>
          <div className={styles.dividerHalf}></div>
        </ResponsiveBox>
      </div>
    </>
  );
};

export default Background;
