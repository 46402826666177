// React
import React, { useState, useEffect } from 'react';

// Local Imports
import { Header, Footer } from '../../layouts';
import { ResponsiveBox, Cta, Loader } from '../../components';
import BlogList from './js/blogList';
import BlogDetail from './js/blogDetail';

// React Bootstrap
import { Row, Col } from 'react-bootstrap';

// Styles
import styles from './blogs.module.scss';

const Blogs = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [blogDetail, setBlogDetail] = useState();
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  const handleShowList = () => {
    setShowDetail(false);
    setBlogDetail();
    setRelatedBlogs([]);
  };

  const handleSelectBlog = (slug) => {
    setBlogDetail(blogs.find(item => item.slug === slug));
    setRelatedBlogs(blogs.filter(item => item.slug !== slug).slice(0, 3));
    setShowDetail(true);
  }

  useEffect(() => {
    setLoading(true);
    fetch(
      'https://api.cosmicjs.com/v1/first-paddle-site/objects?pretty=true&hide_metafields=true&type=blog&read_key=ZH1tDhHy5K3JCyli64UnyeJuIQiXhNa54RXYQcF1G7BMxkHnsR&limit=20&props=slug,title,content,metadata,'
    )
      .then((response) => response.json())
      .then((data) => {
        setBlogs(data.objects.sort((a, b) => new Date(b.metadata && b.metadata.published_date) - new Date(a.metadata && a.metadata.published_date)));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <div className={styles.divider}></div>

      {!showDetail ? (
        blogs && blogs.length > 0 ? (
          <BlogList blogs={blogs} selectBlog={handleSelectBlog} />
        ) : (
          <p className={styles.text}>Blogs coming soon!</p>
        )
      ) : (
        <BlogDetail showList={handleShowList} blog={blogDetail} relatedBlogs={relatedBlogs} selectBlog={handleSelectBlog} />
      )}

      <div className={styles.divider}></div>

      <Cta />

      <Footer />
    </>
  );
};

export default Blogs;
