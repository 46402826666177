// React
import React from "react";

// React router
import { useHistory } from "react-router-dom";

// React bootstrap
import { Row, Col } from "react-bootstrap";

// Local Imports
import { Header, Footer } from "../../layouts";
import { ResponsiveBox, PageTitle, Tool, Cta } from "../../components";

// styles
import styles from "./project.module.scss";

// data
import projects from "../../assets/data/projects.json";

//MetaTags
import MetaTags from "react-meta-tags";

const Project = () => {
  const history = useHistory();

  const displayProjects = projects.projects.map((item, index) => {
    return (
      <Col xs={12} lg={6} key={index}>
        <div className={styles.outerCover1}>
          <div className={`${styles.cover} ${styles.cover1}`}>
          <a href={item.link} target="_blank">
              <div className={styles.project} style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)), url(${item.image})`}}></div>
              </a>
            <div className={styles.content}>
              <h4>{item.title}</h4>
              <div className={styles.flexContainer}>
                <h6>{item.subtitle}</h6>
                <a
                  className={styles.moreTxt}
                  target="_blank"
                  href={item.link}
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  });

  return (
    <>
      <MetaTags>
        <meta
          name="title"
          content="Work | Web development in Nepal | Mobile app development in Nepal |Web Design"
        />
        <meta
          name="description"
          content="Firstpaddle has done varieties of projects that is doing very good in the present market  within this very short period of time. The accompolished projects are assissting our clients to grow their business to a  significant level."
        />
        <link rel="canonical" href="https://www.firstpaddle.com/projects" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="5 days" />
        <meta name="author" content="Firstpaddle Pvt. Ltd" />
      </MetaTags>

      <Header />
      <ResponsiveBox>
        <div className={styles.divider}></div>

        <h2 className={styles.heading}>Projects</h2>
        <p className={styles.subHeading}>
          We have a proven track record of building high quality solutions for
          customers.
        </p>
        <Row className={`gutter-5`}>
          {displayProjects}
        </Row>
        <div className={styles.divider}></div>
      </ResponsiveBox>

      <Cta />
      <Footer />
    </>
  );
};

export default Project;



// {
//   "image": "https://i.ibb.co/6bZc7RK/two.jpg",
//   "title": "Samriddhi",
//   "subtitle": "Hotel booking site",
//   "link": "https://samriddhi.firstpaddle.com"
// },